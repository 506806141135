<template>
  <v-navigation-drawer
    app clipped fixed left bottom
    mobile-breakpoint="600"
    v-model="drawer"
    class="node-sidemenu"
    width="290"
    style=""
    ref="drawerElement"
  >

    <div class="d-flex flex-column justify-space-between" style="height:100%">
      <div>
        <AccesspointSwitch>
        </AccesspointSwitch>

        <v-list nav focusable class="console-navigation" data-cy="console-sidemenu-managementConsoleMenu">
          <v-subheader>
            <v-icon>mdi-tune-vertical</v-icon>
            {{$t('console.management_console')}}
          </v-subheader>

          <!-- TODO: Check the permissions -->
          <v-list-item-group>
            <v-list-item to="/console/index" data-cy="console-sidemenu-myAccount">
              <v-list-item-icon>
                <v-icon>mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="pl-2">
                {{$t('console.my_account')}}
              </v-list-item-content>
            </v-list-item>

            <v-list-item to="/console/users" v-if="!isTenantSubscriptionExpired && $canListUser()" data-cy="console-sidemenu-users">
              <v-list-item-icon>
                <v-icon>mdi-account-group</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="pl-2">
                {{$t('console.users')}}
              </v-list-item-content>
            </v-list-item>

            <v-list-item
                to="/console/subscriptions"
                v-if="canShowSubscriptionPage"
                :class="{ 'v-list-item--active': isActiveRoute('/console/subscription-plans') }"
                data-cy="console-sidemenu-subscriptions"
            >
              <v-list-item-icon>
                <v-icon>mdi-star-box</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="pl-2">
                {{$t('console.subscriptions')}}
              </v-list-item-content>
            </v-list-item>

            <v-list-item
                to="/console/subscription-link"
                v-if="canShowSubscriptionLink"
                data-cy="console-sidemenu-subscriptions"
            >
              <v-list-item-icon>
                <v-icon>mdi-star-box</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="pl-2">
                {{$t('console.subscriptions')}}
              </v-list-item-content>
            </v-list-item>

            <v-list-item to="/console/usage" v-if="$canControlTenantConfig()" data-cy="console-sidemenu-resourceUsage">
              <v-list-item-icon>
                <v-icon>mdi-home-analytics</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="pl-2">
                {{$t('console.resourceUsage')}}
              </v-list-item-content>
            </v-list-item>

            <v-list-item to="/console/storage" v-if="$canControlTenantConfig()" data-cy="console-sidemenu-storageUsage">
              <v-list-item-icon>
                <v-icon>mdi-database</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="pl-2">
                {{$t('console.storageUsage')}}
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              v-if="showingBrandingButton"
              to="/console/branding"
              data-cy="console-sidemenu-branding"
            >
              <v-list-item-icon>
                <v-icon>mdi-watermark</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="pl-2">
                {{$t('menus.branding.branding')}}
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              to="/console/contacts"
              v-if="(!isTenantSubscriptionExpired && !isRootUser) && $canControlTenantConfig()"
              data-cy="console-sidemenu-contacts"
            >
              <v-list-item-icon>
                <v-icon>mdi-email-open</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="pl-2">
                {{$t('console.contacts')}}
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/console/tenants" v-if="isRootUser" data-cy="console-sidemenu-tenants">
              <v-list-item-icon>
                <v-icon>mdi-domain</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="pl-2">
                {{$t('console.tenants')}}
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/console/system-usage" v-if="isRootUser" data-cy="console-sidemenu-tenantusagedata">
              <v-list-item-icon>
                <v-icon>mdi-home-analytics</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="pl-2">
                {{$t('console.tenantUsageData')}}
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>

          <v-list-item
              to="/console/labels"
              v-if="!isRootUser && $canControlTenantConfig()"
              data-cy="console-sidemenu-labels"
          >
            <v-list-item-icon>
              <v-icon>mdi-label-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="pl-2">
              {{$t('console.labels')}}
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>

      <div v-if="showingUpgradeButton" class="d-flex flex-column text-center">
        <span v-if="getSubscriptionDaysLeft">
          {{ $tc('layouts.main.subscriptionDaysLeft', getSubscriptionDaysLeft) }}
        </span>
        <v-btn color="primary" elevation=0 to="/console/subscriptions">
          <v-icon>mdi-crown-circle</v-icon>
          {{$t('layouts.main.upgradeSubscription')}}
        </v-btn>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>

// import router from "@/routes/router";
import AccesspointSwitch from '../components/AccesspointSwitch.vue'

export default {
  name: "ConsoleSidemenu",
  components: {
    AccesspointSwitch,
  },
  props: {
    subscriptionType: {
      type: String,
      required: true,
    },
    subscriptionName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      drawer: null,
    };
  },
  watch: {
    getSidemenuToggle() {
      this.drawer = !this.drawer;
    }
  },
  methods: {
    isActiveRoute(route) {
      return this.$route.path === route;
    }
  },
  computed: {
    getSidemenuToggle() {
      const GETSIDEMENUTOGGLE = this.$store.getters.keywords.MAIN.GETSIDEMENUTOGGLE;
      return this.$store.getters[GETSIDEMENUTOGGLE];
    },

    showingUpgradeButton() {
      const GETSUBSCRIPTION = this.$store.getters.keywords.SYSTEM.GETSUBSCRIPTION;
      const getSubscription = this.$store.getters[GETSUBSCRIPTION];

      return  this.$canControlTenantConfig() &&
              !this.$isFCPExtension &&
              !this.$isAdobeExtension &&
              getSubscription &&
              getSubscription.subscription &&
              getSubscription.subscription.name &&
              getSubscription.subscription.name == 'trial';
    },
    showingBrandingButton() {
      return !this.isTenantSubscriptionExpired
        && !this.isRootUser
        && this.$canControlTenantConfig()
        && (this.subscriptionName === 'Gold' || this.subscriptionType === 'enterprise');
    },
    getSubscriptionDaysLeft() {
      const GETACTIVETENANT = this.$store.getters.keywords.AUTH.GETACTIVETENANT;
      const activeTenant = this.$store.getters[GETACTIVETENANT];
      if (activeTenant?.subscription?.subscriptionEnd) {
        return activeTenant.subscription.subscriptionEnd.days <= 0
          ? 0
          : activeTenant?.subscription?.subscriptionEnd?.days;
      }

      return 0;
    },
    isRootUser() {
      const ROOTFOLDERID = this.$store.getters.keywords.COMMON.ROOTFOLDERID;
      const rootFolderId = this.$store.getters[ROOTFOLDERID];

      const ROOTNODEID = this.$store.getters.keywords.AUTH.ROOTNODEID;
      const rootNodeId = this.$store.getters[ROOTNODEID];

      return rootNodeId === rootFolderId;
    },
    isTenantSubscriptionExpired() {
      const TENANTISEXPIRED  = this.$store.getters.keywords.AUTH.TENANTISEXPIRED;
      return this.$store.getters[TENANTISEXPIRED];
    },
    canShowSubscriptionPage() {
      return this.$canControlTenantConfig()
          && !this.isRootUser
          && !this.$isFCPExtension()
          && !this.$isAdobeExtension()
          && this.subscriptionType !== 'enterprise';
    },
    canShowSubscriptionLink() {
      return this.$canControlTenantConfig()
          && !this.isRootUser
          && this.subscriptionType !== 'enterprise'
          && (this.$isFCPExtension() || this.$isAdobeExtension());
    },
  },
}
</script>

<style>
.theme--dark .console-navigation {
  background-color: #232b36 !important;
}

.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: #f5f5f5 !important;
}

.theme--dark .console-navigation a:hover {
  text-decoration: none;
}

.console-navigation .v-list-item--active {
  color: #3dabff !important;
}
</style>
