<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    :disabled="disabled"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    :width="$vuetify.breakpoint.smAndUp ? 600 : 'auto'"
    style="z-index: 1100 !important;"
  >

    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" >
        <slot></slot>
      </div>
    </template>

    <v-card >
      <v-card-title class="primary d-flex justify-space-between">
        <span>{{ $t('routes.pod.generateLabels') }}</span>
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="scrollable mt-4">
        <div v-if="noThingsSelected" class="error mb-4">
          {{$t('routes.pod.noSuitableFilesSelected')}}
        </div>


        <v-form ref="form" :disabled="noThingsSelected">
          <div data-cy="podMenu-generatedLabels-selectService">
            <v-radio-group
              v-model="selectedServiceId"
              :label="$t('routes.pod.menuGenerateLabels.selectServiceToUse')+':'"
              @change="selectedServiceChanged"

              class="mt-0 pt-0"
            >
              <v-radio
                v-for="service in metadataServices"
                :key="service.id"
                :label="service.name"
                :value="service.id"
                :disabled="service.id == -1"
                data-cy="dialog-createSubtitle-srtRadio">
              </v-radio>
            </v-radio-group>
          </div>

          <div class="d-flex font-italic text--service-cost">
            <div class="flex-grow-1">{{$t('routes.pod.menuGenerateLabels.mediaSelected')}}</div>
            <div>{{summedDuration | videoDuration}}</div>
          </div>

          <div class="d-flex font-italic text--service-cost">
            <div class="flex-grow-1">{{$t('routes.pod.yourAvailableCredits')}}</div>
            <div>{{creditsLeft }}</div>
          </div>

          <div class="d-flex font-italic text--service-cost">
            <div class="flex-grow-1">{{$t('routes.pod.serviceCost')}}</div>
            <div>{{summedCost}}</div>
          </div>

          <div class="d-flex font-italic text--service-cost">
            <div class="flex-grow-1">{{$t('routes.pod.estimatedRemainingCredits')}}</div>
            <div>{{creditsLeftAfterJob}}</div>
          </div>

          <hr />

          <div v-if="creditsLeftAfterJob < 0 && $canControlTenantConfig()" class="my-2">
            <div>{{$t('routes.pod.menuGenerateLabels.wantToBuyBundle')}}</div>
            <div class="d-flex justify-space-around">
              <v-btn v-on:click="dialog = false" color="primary" elevation=0>{{$t('common.no')}}</v-btn>
              <v-btn to="/console/subscriptions" color="primary" elevation=0>{{$t('common.yes')}}</v-btn>
            </div>
          </div>

          <!-- <UtilityPodsErrors></UtilityPodsErrors> -->

          <!-- <div v-for="item in runnablePods" v-bind:key="item.id" class="my-2 d-flex justify-space-between speech-to-text-file">
            <div class="text-truncate" data-cy="podMenu-generatedLabels-runnablePodName">{{item.pod.name}}</div>
            <div v-if="item.metadata && item.metadata.duration">{{ item.metadata.duration | videoDuration }}</div>
          </div> -->

          <v-list dense>
            <v-list-item v-for="item in pods" v-bind:key="item.id" >

              <v-list-item-icon style="align-self: center;">
                <v-icon v-if="isPodRunnable(item)" color="success">mdi-check-bold</v-icon>
                <v-icon v-else color="error">mdi-close-thick</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title data-cy="createTranscript-speechToText-runnablePodName">
                  {{item.name}}
                </v-list-item-title>
                <v-list-item-subtitle v-if="getUtilityPod(item) && getUtilityPod(item).error" class="error--text">
                  {{$replaceT(getUtilityPod(item).error.code, getUtilityPod(item).error.params, getUtilityPod(item).error.content)}}
                </v-list-item-subtitle >
                <v-list-item-subtitle v-if="hasNoText(item)" class="error--text">
                  {{$t("backend.ERR42")}}
                </v-list-item-subtitle>

              </v-list-item-content>

              <v-list-item-action v-if="isPodRunnable(item) && getUtilityPod(item).metadata && typeof getUtilityPod(item).metadata.duration == 'number'">
                {{ getUtilityPod(item).metadata.duration | videoDuration }}
              </v-list-item-action>

              <v-list-item-action v-if="pods.length > 1">
                <v-btn icon @click="toggleSelection(item)">
                  <v-icon >mdi-close</v-icon>
                </v-btn>
              </v-list-item-action>

            </v-list-item>
          </v-list>

          <div v-show="successMessage" class="success" data-cy="podMenu-generatedLabels-successMessage">{{successMessage}}</div>
          <div v-show="errorMessage" class="error" data-cy="podMenu-generatedLabels-errorMessage">{{errorMessage}}</div>

        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <div v-if="creditsLeftAfterJob >= 0" class="mx-2 text-center">
          <v-btn
          v-bind:disabled="isRunJobsDisabled"
          v-on:click.prevent="runJobs()"
          elevation=0
          color="primary"
          data-cy="podMenu-generatedLabels-generate-btn">
            {{$t('routes.pod.menuGenerateLabels.generate')}}
          </v-btn>
        </div>

        <v-btn text @click="dialog = false">{{$t('common.close')}}</v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>
</template>

<script>
import DialogActionKeys from '@/components/mixin/DialogActionKeys';

export default {
  mixins: [DialogActionKeys],
  name: "LabelSelectedDialog",
  props: {
    pods: {
      type: Array,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,

      selectedService: null,
      selectedServiceId: '',

      // selectedServiceRules: [
      //   v => !!v || this.$t('routes.pod.menuGenerateLabels.serviceProviderRequired'),
      // ],

      outputLanguage: '',
      startedServices: [],
      preventDoubleclick: false,

      errorMessage: '',
      successMessage: '',
    };
  },
  watch: {
    dialog(newValue) {
      if (newValue) {
        this.updatePodsUtility();
      } else {
        this.resetForm();
      }
    }
  },
  methods: {
    resetForm() {
      this.selectedService = null;
      this.selectedServiceId = '';

      this.outputLanguage = '';
      this.startedServices = [];
      this.preventDoubleclick = false;

      this.errorMessage = '';
      this.successMessage = '';

      const RESETUTILITYPODS = this.$store.getters.keywords.SERVICE.RESETUTILITYPODS;
      this.$store.dispatch(RESETUTILITYPODS);

    },
    selectedServiceChanged() {
      this.selectedService = this.metadataServices.find(x => x.id == this.selectedServiceId);
      this.updatePodsUtility();
    },
    toggleSelection(pod) {
      const TOGGLESELECTEDPOD = this.$store.getters.keywords.POD.TOGGLESELECTEDPOD;
      this.$store.dispatch(TOGGLESELECTEDPOD, pod);

      this.updatePodsUtility();
    },
    updatePodsUtility() {
      this.errorMessage = '';
      this.successMessage = '';
      this.preventDoubleclick = false;

      if (this.suitablePods.length == 0) {
        const RESETUTILITYPODS = this.$store.getters.keywords.SERVICE.RESETUTILITYPODS;
        this.$store.dispatch(RESETUTILITYPODS);
        return;
      }

      if (!this.selectedService || !this.selectedService.alias) {
        return;
      }

      if (this.startedServices.indexOf(this.selectedService.alias) >= 0) {
        return;
      }

      const payload = {
        serviceAlias: this.selectedService.alias,
        pods: this.suitablePods,
      };

      const UPDATEPODSUTILITY = this.$store.getters.keywords.SERVICE.UPDATEPODSUTILITY;
      this.$store.dispatch(UPDATEPODSUTILITY, payload);
    },
    runJobs: function() {
      this.errorMessage = "";
      this.successMessage = "";

      if (!this.selectedService.alias) {
        return;
      }

      const payload = {
        serviceAlias: this.selectedService.alias,
        utilityPods: this.runnablePods,
      };

      let self = this;
      this.preventDoubleclick = true;

      const RUNSERVICE = this.$store.getters.keywords.SERVICE.RUNSERVICE;

      this.$store.dispatch(RUNSERVICE, payload)
      .then(function() {
        if (self.utilityPodsErrors.length == 0) {
          self.successMessage = self.$t("routes.pod.menuGenerateLabels.operationQueued");
          self.startedServices.push(payload.serviceAlias);
        }
      })
      .catch(function(error) {
        if (error && error.response && error.response.data && error.response.data.error && error.response.data.error.message) {
          self.errorMessage = error.response.data.error.message;
        } else {
          self.errorMessage = "Error!";
          console.log(error);   // unhandled error
        }
      })
      .finally(function() {
        self.preventDoubleclick = false;
      });
    },
  },
  computed: {
    noThingsSelected() {
      return !this.suitablePods || this.suitablePods.length == 0;
    },
    metadataServices: function() {
      let services = [];
      const GETREKOGNITION = this.$store.getters.keywords.SERVICE.GETREKOGNITION;
      for(const service of this.$store.getters[GETREKOGNITION]) {
        services.push(service);
      }

      return services;
    },
    getMlLabels: function() {
      const GETMLLABELS = this.$store.getters.keywords.POD.LABEL.GETMLLABELS;
      return this.$store.getters[GETMLLABELS];
    },
    getAmazonTextDetectedLabels: function() {
      //TODO: filter the ML labels by type 'aws-detecting-text'
      return this.getMlLabels;
    },
    suitablePods: function() {
      let suitablePods = [];

      const GETSELECTEDVIDEOPODS = this.$store.getters.keywords.POD.GETSELECTEDVIDEOPODS;
      for (const pod of this.$store.getters[GETSELECTEDVIDEOPODS]) {
        suitablePods.push(pod);
      }

      const GETSELECTEDIMAGEPODS = this.$store.getters.keywords.POD.GETSELECTEDIMAGEPODS;
      for (const pod of this.$store.getters[GETSELECTEDIMAGEPODS]) {
        suitablePods.push(pod);
      }

      return suitablePods;
    },
    getUtilityPods: function() {
      const GETUTILITYPODS = this.$store.getters.keywords.SERVICE.GETUTILITYPODS;
      return this.$store.getters[GETUTILITYPODS];
    },
    utilityPodsErrors: function() {
      const GETUTILITYPODSERRORS = this.$store.getters.keywords.SERVICE.GETUTILITYPODSERRORS;
      return this.$store.getters[GETUTILITYPODSERRORS];
    },
    runnablePods: function() {
      const GETRUNNABLEPODS = this.$store.getters.keywords.SERVICE.GETRUNNABLEPODS;
      let runnablePods = this.$store.getters[GETRUNNABLEPODS];

      return runnablePods.filter(x => !this.hasNoText(x.pod));
    },
    creditsLeft: function() {
      if (this.getUtilityPods && this.getUtilityPods[0] && this.getUtilityPods[0].metadata) {
        return this.getUtilityPods[0].metadata.available;
      }

      return 0;
    },
    creditsLeftAfterJob: function() {
      return this.creditsLeft - this.summedCost;
    },
    summedDuration: function() {
      let sum = 0;
      for (let i = 0; i < this.getUtilityPods.length; i++) {
        if (this.getUtilityPods[i].metadata &&
            this.getUtilityPods[i].metadata.duration !== null &&
            this.getUtilityPods[i].metadata.duration !== undefined) {
          sum += this.getUtilityPods[i].metadata.duration;
        }
      }
      return sum;
    },
    summedCost: function() {
      let sum = 0;
      for (let i = 0; i < this.getUtilityPods.length; i++) {
        if (this.getUtilityPods[i].metadata &&
            this.getUtilityPods[i].metadata.cost !== null &&
            this.getUtilityPods[i].metadata.cost !== undefined) {
          sum += this.getUtilityPods[i].metadata.cost;
        }
      }
      return sum;
    },
    isPodRunnable: function() {
      const ISPODRUNNABLE = this.$store.getters.keywords.SERVICE.ISPODRUNNABLE;
      return function(pod) {
        return this.$store.getters[ISPODRUNNABLE](pod) && !this.hasNoText(pod);
      };
    },
    getUtilityPod: function() {
      const GETUTILITYPOD = this.$store.getters.keywords.SERVICE.GETUTILITYPOD;
      return function(pod) {
        return this.$store.getters[GETUTILITYPOD](pod);
      };
    },
    isRunJobsDisabled: function() {
      if (!this.selectedService ||
          !this.pods || !this.pods.length ||
          this.runnablePods.length == 0 ||
          this.runnablePods.length !== this.pods.length ||
          this.preventDoubleclick == true ||
          this.startedServices.indexOf(this.selectedService.alias) >= 0
      ) {
        return true;
      }

      return false;
    },
    hasNoText: function() {
      const textDetectionServices = ['aws-detecting-text'];

      return function(thePod) {
        if (!this.selectedService) {
          return false;
        }

        if (!textDetectionServices.includes(this.selectedService.alias)) {
          return false;
        }

        if (thePod.features && Array.isArray(thePod.features) && thePod.features.length > 0) {
          const hasText = thePod.features.find((o) => o.key === 'hasText');

          if (hasText && hasText.value === 'no') {
            return true;
          }
        }

        return false;
      }
    },
  }
}
</script>

<style>
.text--service-cost {
  font-size: 13px;
  letter-spacing: 1px;
  margin-bottom: 5px;
}
</style>
