<template>
  <div v-show="filteredLabels.length > 0" class="scrollable pa-0 ma-0" data-cy="podMenu-labels-mlLabels" style="overflow-y: auto; min-height: 100px;">

    <table class="mt-2 pod-labels-table" data-cy="podMenu-labels-mlLabels-tableHeaders">
      <thead>
        <tr>
          <td v-if="isVideoPod">{{$t('routes.pod.menuEditLabels.start')}}</td>
          <td v-if="isVideoPod">{{$t('routes.pod.menuEditLabels.durationShort')}}</td>
          <td>{{$t('routes.pod.menuEditLabels.label')}}</td>
        </tr>
      </thead>
    </table>

    <table class="mt-2 pod-labels-table flex-grow-1 " data-cy="podMenu-labels-mlLabels-table" style="overflow-y: auto;">
      <tbody active-class="primary--text">
        <tr
          v-for="label in filteredLabels"
          v-bind:key="label.id"
          v-bind:class="isActiveLabel(label)"
          v-on:click="selectItem(label)"
          v-on:dblclick="setEditItem(label)"
          v-on:contextmenu.prevent="contextMenuClick(label, $event)"
          ref="subtitleLineRef"
          class="prevent-select"
          data-cy="podMenu-labels-mlLabels-row">

          <td data-cy="podMenu-labels-mlLabels-start" v-if="isVideoPod">{{label.start | toTimeMilliseconds}}</td>
          <td data-cy="podMenu-labels-mlLabels-duration" v-if="isVideoPod">
            {{ label.duration.toFixed(2) }}{{$t('routes.pod.durationSecondsShort')}}
          </td>
          <td v-bind:ref="'label' + label.id" style="white-space: pre-wrap;" data-cy="podMenu-labels-mlLabels-content">
            {{label.content}}
          </td>

        </tr>
      </tbody>
    </table>

    <v-menu
      v-model="showContextMenu"
      :position-x="positionX"
      :position-y="positionY"
      v-bind:close-on-content-click="false"
      close-on-click
      offset-y bottom
      absolute
      z-index="20"
    >
      <v-list data-cy="list-podItem-actions-list">
        <v-list-item @click="contextMenuEdit()">
          <v-list-item-icon>
            <v-icon small>mdi-pencil</v-icon>
          </v-list-item-icon>
          <v-list-item-content data-cy="">
            <v-list-item-title>{{$t('routes.pod.editLabel')}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="contextMenuDelete()">
          <v-list-item-icon>
            <v-icon small>mdi-delete</v-icon>
          </v-list-item-icon>
          <v-list-item-content data-cy="">
            <v-list-item-title>{{$t('routes.pod.deleteLabel')}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list>
    </v-menu>

  </div>
</template>

<script>
export default {
  name: "PodMlLabelsList",
  props: {
    pod: {
      type: Object,
      required: true,
    },
    value: String,
    labelsArray: Array,
    mlShowType: {
      type: String,
      required: true,
    },
    confidenceLevel: {
      type: Number,
      required: false,
    },
    disableTypeFilter: {
      type: Boolean,
      required: false,
    },
    // disableEditing: {
    //   type: Boolean,
    //   required: false,
    // },
  },
  data: function() {
    return {
      metaShown: true,
      chipsModel: null,

      showContextMenu: false,
      positionX: 0,
      positionY: 0,
      labelItem: null,
    };
  },
  methods: {
    hideExpandMeta: function() {
      this.metaShown = !this.metaShown;
    },
    selectItem: function(item) {
      if (item.start || item.start === 0) {
        const SETSEEKTIMEEVENT = this.$store.getters.keywords.PLAYER.SETSEEKTIMEEVENT;
        this.$store.commit(SETSEEKTIMEEVENT, item.start);
      }
      this.$emit('input', item.id);
    },
    setEditItem: function(item) {
      // if (this.disableEditing) {
      //   return;
      // }

      this.$emit('setEditItem', item);
    },
    scrollToActiveLine: function() {
      if (!this.$refs.subtitleLineRef) {
        return;
      }

      for(let i = 0; i < this.$refs.subtitleLineRef.length; i ++) {
        const classList = Array.from(this.$refs.subtitleLineRef[i].classList);
        if (classList.indexOf('active-subtitle') >= 0) {
          this.$refs.subtitleLineRef[i].scrollIntoView(true);
          break;
        }
      }
    },
    contextMenuClick(item, event) {
      this.selectItem(item);

      this.labelItem = item;
      this.positionX = event.clientX;
      this.positionY = event.clientY;
      this.$nextTick(() => {
        this.showContextMenu = true;
      });
    },
    contextMenuEdit() {
      this.showContextMenu = false;
      this.setEditItem(this.labelItem);
    },
    contextMenuDelete() {
      this.showContextMenu = false;

      if (!this.labelItem) {
        return;
      }

      const payload = {
        podID: this.pod.id,
        labelID: this.labelItem.id,
      };

      let DELETEMLLABEL = this.$store.getters.keywords.POD.LABEL.DELETEMLLABEL;
      let VERB = this.$store.getters.keywords.POD.LABEL.DELETEMLLABEL;
      this.$store.dispatch(VERB, payload)
        .then(() => {
          this.$store.commit(DELETEMLLABEL, this.labelItem.id);
        });
    },
  },
  computed: {
    filteredLabels: function() {
      return this.filteredByConfidence;
    },
    filteredByType: function() {
      if (this.disableTypeFilter) {
        return this.labelsArray;
      }

      switch (this.mlShowType) {
        case "lines": return this.labelsArray.filter(x => x.type == "line");
        case "words": return this.labelsArray.filter(x => x.type == "word");
        default: return this.labelsArray;
      }
    },
    filteredByConfidence : function() {
      return this.filteredByType.filter(x => x.confidence >= this.confidenceLevel);
    },
    playerCurrentTime: function() {
      const CURRENTTIME = this.$store.getters.keywords.PLAYER.CURRENTTIME;
      return this.$store.getters[CURRENTTIME];
    },
    isActiveLabel: function() {
      return function(item) {
        return {
            'active-subtitle':
                (this.playerCurrentTime >= item.start &&
                this.playerCurrentTime < item.end),
        };
      }
    },
    isVideoPod: function() {
      const ISVIDEOPOD = this.$store.getters.keywords.POD.ISVIDEOPOD;
      return this.$store.getters[ISVIDEOPOD];
    },
  },
  watch: {
    chipsModel: function(newValue) {
      this.$emit('input', newValue);
    },
    value: function(newValue) {
      this.chipsModel = newValue;
    },
    playerCurrentTime: function(time) {
      time;
      // console.log("Time updated", time);
      this.scrollToActiveLine();
    },
  }
}
</script>

<style>

</style>
