import Api from '../../api/Api'

export default {
  namespaced: true,
  state: {
    accesslinks: [],

    // For public api calls
    accesslinkInfo: {},
    accesslinkPods: [],
    accesslinkNodes: [],

    viewPod: null,
    viewNode: null,

    comments: [],
    listViewType: 'list',
  },
  getters: {
    getAccesslinks: function(state) {
      return state.accesslinks;
    },

    // for public api calls
    getAccesslinkInfo: function(state) {
      return state.accesslinkInfo;
    },
    getAccesslinkPods: function(state) {
      return state.accesslinkPods;
    },
    getAccesslinkNodes: function(state) {
      return state.accesslinkNodes;
    },
    getViewPod: function(state) {
      return state.viewPod;
    },
    getViewNode: function(state) {
      return state.viewNode;
    },
    getComments: function(state) {
      return state.comments;
    },
    getListViewType: function(state) {
      return state.listViewType;
    }
  },
  mutations: {
    setAccesslinks: function(state, links) {
      state.accesslinks = links;
    },
    removeAccesslink: function(state, accesslinkId) {
      for (let i = 0; i < state.accesslinks.length; i++) {
        if (state.accesslinks[i].id === accesslinkId) {
          state.accesslinks.splice(i, 1);
          break;
        }
      }
    },

    // For public api calls
    setAccesslinkInfo: function(state, token) {
      state.accesslinkInfo = token;
    },
    setAccesslinkPods: function(state, pods) {
      state.accesslinkPods = pods;
    },
    setAccesslinkNodes: function(state, nodes) {
      state.accesslinkNodes = nodes;
    },
    setViewPod: function(state, pod) {
      state.viewPod = pod;
    },
    setViewNode: function(state, node) {
      state.viewNode = node;
    },
    addComment: function(state, comment) {
      if (comment.start !== null) {
        comment.start /= 1000;
      }
      if (comment.end !== null) {
        comment.end /= 1000;
      }

      state.comments.push(comment);
    },
    updateComment: function(state, comment) {
      if (comment.start !== null) {
        comment.start /= 1000;
      }
      if (comment.end !== null) {
        comment.end /= 1000;
      }

      for (let i = 0; i < state.comments.length; i++) {
        if (state.comments[i].id == comment.id) {
          Object.assign(state.comments[i], comment);
          break;
        }
      }
    },
    removeComment: function(state, commentId) {
      const newList = [];
      for (const comment of state.comments) {
        if (comment.id !== commentId) {
          newList.push(comment);
        }
      }

      state.comments = newList;
    },
    toggleListViewType: function(state) {
      if (state.listViewType === 'list') {
        state.listViewType = 'grid';
      } else {
        state.listViewType = 'list';
      }
    }
  },
  actions: {
    refreshAccesslinks: function (context, nodeId) {
      return Api.accesslink.getAccesslinksByNode(nodeId)
        .then(function (response) {
          context.commit('setAccesslinks', response.data || []);
        })
    },

    createAccesslink: function(context, payload) {
      return Api.accesslink.createAccesslink(payload)
      .then(function(response) {
        return response.data;
      });
    },

    updateAccesslink: function(context, payload) {
      const id = payload.id;
      delete payload.id;
      return Api.accesslink.updateAccesslink(id, payload)
      .then(function(response) {
        return response.data;
      });
    },

    deleteAccesslink: function (context, accessLinkId) {
      return Api.accesslink.deleteAccesslink(accessLinkId)
      .then(function(response) {
        context.commit('removeAccesslink', accessLinkId);
        return response.data;
      });
    },

    inviteAccesslink: function (context, payload) {
      return Api.accesslink.inviteAccesslink(payload)
      .then(function(response) {
        return response.data;
      });
    },

    // External call
    getAccesslinkInfo: function(context, code) {
      //context.commit('setAccesslinkInfo', {});
      return Api.accesslink.getAccesslinkInfo(code)
      .then(function(response) {
        context.commit('setAccesslinkInfo', response.data);
        return response.data;
      })
    },
    // External call
    authorizeAccesslink: function(context, data) {
      return Api.accesslink.authorizeAccesslink(data)
      .then(function(response) {
        if (response.data.accesstoken) {
          context.commit('setAccesslinkInfo', response.data);
        }

        return response.data;
      })
    },

    // External call
    refreshAccesslinkContents: function(context) {
      return Api.accesslink.getAccesslinkContents({
        accesstoken: context.getters.getAccesslinkInfo.accesstoken,
      }).then(function (response) {
        context.commit('setAccesslinkPods', response.data.pods);
        context.commit('setAccesslinkNodes', response.data.nodes);

        if (response.data.pods.length === 1) {
          context.commit('setViewPod', response.data[0]);
        } else if (response.data.nodes.length === 1 && response.data.nodes[0].pods.length === 1) {
          context.commit('setViewPod', response.data.nodes[0].pods[0]);
        }
      });
    },
    setViewPod: function(context, pod) {
      context.commit('setViewPod', pod);
    },
    // External call
    addComment: function(context, payload) {
      // add the access token
      payload.accesstoken = context.getters.getAccesslinkInfo.accesstoken;

      return Api.accesslink.addComment(payload)
      .then(function(response) {
        context.commit('addComment', response.data);
        return response.data;
      });
    },
    // External call
    updateComment: function(context, payload) {
      // add the access token
      payload.accesstoken = context.getters.getAccesslinkInfo.accesstoken;

      return Api.accesslink.updateComment(payload)
      .then(function(response) {
        context.commit('updateComment', response.data);
        return response.data;
      });
    },
    // External call
    deleteComment: function(context, payload) {
      // add the access token
      payload.accesstoken = context.getters.getAccesslinkInfo.accesstoken;

      return Api.accesslink.deleteComment(payload)
        .then(function() {
          context.commit('removeComment', payload.id);
        });
    },
    // External call
    downloadAccesslinkFiles: function(context, payload) {
      // payload = {code, 'id-token', excludeAssets,}
      // console.log("context", context);

      if (!context?.getters?.getAccesslinkInfo?.accesstoken) {
        return;
      }

      payload.accesstoken = context.getters.getAccesslinkInfo.accesstoken;


      return Api.accesslink.downloadAccesslinkFiles(payload);
    },
  }
}
