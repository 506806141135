import Vue from 'vue'

Vue.filter('truncateText', function (text, length, suffix) {
  if (text.length > length) {
    return text.substring(0, length) + (suffix || '...');
  } else {
    return text;
  }
});

Vue.filter('truncateString', function (text, options) {
  if (text.length > options.maxLength) {
    if (!options?.endOffset) {
      return text.substring(options.start || 0, options.maxLength) + (options.suffix || '...');
    }

    return text.slice(options.start || 0, options.startOffset || 7) + '...' + text.slice(-options.endOffset || 7);
  } else {
    return text;
  }
});

// Convert video and audio bitrate to human-readable format and round it without decimals.
Vue.filter('convertBitrate', function (bitrate) {
  if (bitrate < 1000) {
      return bitrate + ' bps';
  } else if (bitrate < 1000000) {
      return Math.round(bitrate / 1000) + ' kbps';
  } else {
      return Math.round(bitrate / 1000000) + ' Mbps';
  }
});

// Round the number to upper value defining the number of decimals. It could also be rounded to number without decimals
Vue.filter('roundNumber', function (number, decimals) {
  return number.toFixed(decimals);
});

// Convert video and audio duration to human-readable format
Vue.filter('formatDuration', function (duration) {
  let hours = Math.floor(duration / 3600);
  let minutes = Math.floor((duration - hours * 3600) / 60);
  let seconds = Math.floor(duration - hours * 3600 - minutes * 60);
  return hours + 'h ' + minutes + 'm ' + seconds + 's';
});
