<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    :disabled="disabled"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    :width="$vuetify.breakpoint.smAndUp ? 600 : 'auto'"
    style="z-index: 1100 !important;"
  >

    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" >
        <slot></slot>
      </div>
    </template>

    <v-card data-cy="dialog-createTranscript-modal">
      <v-card-title class="primary d-flex justify-space-between" data-cy="dialog-createTranscript-title">
        <span>{{$t('routes.pod.transcribeSelectedFiles')}}</span>
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="scrollable mt-4">


        <div v-if="noThingsSelected || !suitablePods || suitablePods.length == 0" class="error mb-4">
          {{$t('routes.pod.noSuitableFilesSelected')}}
        </div>

        <v-form ref="form" @submit.prevent="submitForm" :disabled="noThingsSelected || !suitablePods || suitablePods.length == 0">
          <div data-cy="dialog-createTranscript-selectService">
            <!-- <v-select
              v-model="selectedService"
              v-bind:rules="selectedServiceRules"
              v-bind:items="textToSpeechServices"
              item-text="name"
              item-value="id"
              v-bind:label="$t('routes.pod.menuCreateTranscript.selectServiceToUse')"
              v-bind:disabled="jobIsWorking"
              solo flat
              required
              return-object
              single-line
            ></v-select> -->


            <v-radio-group
              v-model="selectedServiceId"
              :label="$t('routes.pod.menuCreateTranscript.selectServiceToUse')"
              v-bind:disabled="jobIsWorking"
              class="mt-0 pt-0"
              mandatory
              :value="-1"
            >

              <v-radio
                v-for="option in textToSpeechServices"
                :key="option.id"
                :value="option.id"
                :label="option.name"
                data-cy="dialog-createTranscript-selectService-option"
              >
              </v-radio>

              <v-radio :value="-1" :key="-1" :label="$t('routes.pod.noneCreateEmptyTranscript')" data-cy="dialog-createTranscript-selectService-none">
              </v-radio>
            </v-radio-group>
          </div>

          <!-- If selected empty transcript file -->
          <div v-if="selectedServiceId == -1">
            <v-text-field
              type="text"
              v-model="transcriptname"
              v-bind:rules="transcriptnameRules"
              v-bind:label="$t('routes.pod.menuTranscript.transcriptName')"
              required block
              solo flat
              data-cy="dialog-createTranscript-transcriptName"
            >
            </v-text-field>

          </div>
          <!-- End If selected empty transcript file -->

          <!-- If selected ML service -->
          <div v-if="pods.length > 0 && selectedServiceId !== -1">
            <div>
              <v-checkbox
                  v-model="autoDetect"
                  v-show="!autoDetectDisabled && !skipTranscribing"
                  v-bind:disabled="autoDetectDisabled"
                  v-bind:label="$t('routes.pod.menuCreateTranscript.autoLangIdent')"

              >
              </v-checkbox>
            </div>

            <div v-show="selectedService && !autoDetect && !skipTranscribing">
              <v-autocomplete
                  v-model="languageCodes"
                  v-bind:items="languages"
                  item-text="name"
                  item-value="value"
                  v-bind:label="$t('routes.pod.menuCreateTranscript.language')"
                  v-bind:disabled="autoDetect"
                  solo flat
                  required
                  return-object
              ></v-autocomplete>
            </div>


            <div class="d-flex font-italic">
              <div class="flex-grow-1">{{$t('routes.pod.menuCreateTranscript.mediaSelected')}}</div>
              <div>{{summedDuration | videoDuration}}</div>
            </div>

            <div class="d-flex font-italic">
              <div class="flex-grow-1">{{$t('routes.pod.serviceCost')}}</div>
              <div>{{summedCost}}</div>
            </div>

            <div class="d-flex font-italic">
              <div class="flex-grow-1">{{$t('routes.pod.yourAvailableCredits')}}</div>
              <div>{{creditsLeft }}</div>
            </div>

            <div class="d-flex font-italic">
              <div class="flex-grow-1">{{$t('routes.pod.estimatedRemainingCredits')}}</div>
              <div v-if="!skipTranscribing">{{creditsLeftAfterJob }}</div>
              <div v-if="skipTranscribing">{{creditsLeft }}</div>
            </div>

            <hr />

            <div v-if="selectedService && selectedService.alias === 'aws-transcribe'" class="d-flex mt-0">
              <v-icon small>mdi-information-outline</v-icon>
              <p class="font-italic mt-2 mb-1">
                {{$t('routes.pod.menuCreateTranscript.minimumCharge')}}
              </p>
            </div>

            <div v-if="selectedService && selectedService.alias === 'google-transcribe'" class="d-flex mt-0">
              <v-icon small>mdi-information-outline</v-icon>
              <p class="font-italic mt-2 mb-1">
                {{$t('routes.pod.menuCreateTranscript.googleMinimumCharge')}}
              </p>
            </div>

            <hr v-if="selectedService" />

            <div v-if="hasMLData" class="mt-2">
              <v-alert
                  outlined
                  color="3dabff"
                  style="line-height: 1.3;"
              >
                <p>{{$t('routes.pod.menuCreateTranscript.infoDeletePreviousData')}}</p>
                <v-checkbox
                  v-model="skipTranscribing"
                  v-bind:label="$t('routes.pod.menuCreateTranscript.skipTranscribing')"
                  dense
                  hide-details
                >
                </v-checkbox>
              </v-alert>
            </div>
          </div>
          <!-- End If selected ML service -->



          <div v-if="$canControlTenantConfig() && creditsLeftAfterJob < 0" class="my-2">
            <div>{{$t('routes.pod.menuCreateTranscript.wantToBuyBundle')}}</div>
            <div class="d-flex justify-space-around">
              <v-btn v-on:click="dialog = false" elevation=0 color="primary">{{$t('common.no')}}</v-btn>
              <v-btn to="/console/subscriptions" elevation=0 color="primary">{{$t('common.yes')}}</v-btn>
            </div>
          </div>

          <!-- <UtilityPodsErrors class="mt-2"></UtilityPodsErrors> -->

          <!-- <hr /> -->

          <!-- <div v-for="item in runnablePods" v-bind:key="item.id" class="my-2 d-flex justify-space-between speech-to-text-file">
            <div class="text-truncate" data-cy="dialog-createTranscript-runnablePodName">{{item.pod.name}}</div>
            <div v-if="item.metadata && typeof item.metadata.duration == 'number'">{{ item.metadata.duration | videoDuration }}</div>
          </div> -->

          <v-list dense>
            <v-list-item v-for="item in pods" v-bind:key="item.id" >
              <v-list-item-icon style="align-self: center;">
                <v-icon v-if="isPodRunnable(item)" color="success">mdi-check-bold</v-icon>
                <v-icon v-else color="error">mdi-close-thick</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title data-cy="dialog-createTranscript-runnablePodName">
                  {{item.name}}
                </v-list-item-title>
                <v-list-item-subtitle v-if="getUtilityPod(item) && getUtilityPod(item).error" class="error--text">
                  {{$replaceT(getUtilityPod(item).error.code, getUtilityPod(item).error.params, getUtilityPod(item).error.content)}}
                </v-list-item-subtitle >
                <v-list-item-subtitle v-if="!isSuitableItem(item)" class="error--text">
                  {{$t('routes.pod.menuCreateTranscript.notSuitableFormat')}}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action v-if="isPodRunnable(item) && getUtilityPod(item).metadata && typeof getUtilityPod(item).metadata.duration == 'number'">
                {{ getUtilityPod(item).metadata.duration | videoDuration }}
              </v-list-item-action>

              <v-list-item-action v-if="pods.length > 1">
                <v-btn icon @click="toggleSelection(item)">
                  <v-icon >mdi-close</v-icon>
                </v-btn>
              </v-list-item-action>

            </v-list-item>
          </v-list>

          <div v-show="successMessage" class="success" data-cy="dialog-createTranscript-successMessage">{{successMessage}}</div>
          <div v-show="errorMessage" class="error" data-cy="dialog-createTranscript-errorMessage">{{errorMessage}}</div>
          <div v-if="errorTranslatable" class="error">
            {{$replaceT(errorTranslatable.code, errorTranslatable.params, errorTranslatable.content)}}
          </div>

        </v-form>
      </v-card-text>

      <v-card-actions>
        <GenerateAudioDialog v-if="showingGenerateAudioBtn" :pod="pods[0]">
          <v-btn color="primary" elevation=0>
            {{$t('routes.pod.menuGenerateAudio.generateAudio')}}
          </v-btn>
        </GenerateAudioDialog>

        <v-spacer></v-spacer>
        <div v-if="selectedServiceId == -1">
          <v-btn
          :disabled="isCreateEmptyTranscriptDisabled"
          @click="createEmptyTranscript"
          text
          data-cy="dialog-createTranscript-ok-btn"
          >
            {{$t('common.ok')}}
          </v-btn>
        </div>

        <div v-if="creditsLeftAfterJob >= 0 && selectedServiceId !== -1" class="mx-2 text-center">
          <v-btn
            v-bind:disabled="isRunJobsDisabled"
            v-on:click.prevent="runJobs()"
            elevation=0
            color="primary"
            data-cy="dialog-createTranscript-runJobs-btn">
            {{$t('routes.pod.menuCreateTranscript.transcribeAudio')}}
          </v-btn>
        </div>

        <v-btn text @click="dialog = false" data-cy="dialog-createTranscript-close-btn">{{$t('common.close')}}</v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>
</template>

<script>
// import MimetypeHelper from "../../../../components/MimetypeHelper.js"
import GenerateAudioDialog from "../../../pods2/components/dialogs/GenerateAudioDialog.vue"
import DialogActionKeys from '@/components/mixin/DialogActionKeys';

export default {
  mixins: [DialogActionKeys],
  name: "TranscribeSelectedDialog",
  components: {
    GenerateAudioDialog,
  },
  props: {
    pods: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,

      errorMessage: '',
      errorTranslatable: null,
      successMessage: '',
      preventDoubleclick: false,
      jobIsWorking: false,
      skipTranscribing: false,

      languageCodes: [],
      autoDetect: true,
      autoDetectDisabled: true,

      serviceOptions: null,
      selectedServiceId: null,
      // selectedService: '',
      selectedServiceRules: [
        v => !!v || this.$t('routes.pod.menuCreateTranscript.serviceProviderRequired'),
      ],


      transcriptname: "",
      transcriptnameRules: [
        v => !!v || this.$t('routes.pod.menuTranscript.invalidTranscriptName')
      ],
    };
  },
  mounted: function() {
    const RESETUTILITYPODS = this.$store.getters.keywords.SERVICE.RESETUTILITYPODS;
    this.$store.dispatch(RESETUTILITYPODS);

    // const REFRESHLIMITS = this.$store.getters.keywords.SERVICE.REFRESHLIMITS;
    // this.$store.dispatch(REFRESHLIMITS);
  },
  watch: {
    dialog(newValue) {
      if (newValue) {
        this.resetForm();
        this.updatePodsUtility();
      }
    },
    selectedService: function(newServices) {
      if (!this.dialog) {
        return false;
      }

      this.updatePodsUtility();

      if (newServices && newServices.alias) {
        this.refreshServiceOptions(newServices.alias);
      }
    },
    showingGenerateAudioBtn() {
      if (this.dialog) {
        this.updatePodsUtility();
      }
    },
    // suitablePods: function() {
    //   this.updatePodsUtility();
    // },
  },
  methods: {
    resetForm() {
      if (this.$refs.form) {
        this.$refs.form.reset();
      }

      this.$nextTick(() => {
        this.errorMessage = '';
        this.errorTranslatable = null;
        this.successMessage = '';
        this.preventDoubleclick = false;
        this.jobIsWorking = false;
        this.skipTranscribing = false;

        this.languageCodes = [];
        this.autoDetect = true;
        this.autoDetectDisabled = true;

        this.serviceOptions = null;
        this.selectedServiceId = null;
        // this.selectedService = '';
        this.transcriptname = '';

        const RESETUTILITYPODS = this.$store.getters.keywords.SERVICE.RESETUTILITYPODS;
        this.$store.dispatch(RESETUTILITYPODS);
      });
    },
    toggleSelection(pod) {
      const TOGGLESELECTEDPOD = this.$store.getters.keywords.POD.TOGGLESELECTEDPOD;
      this.$store.dispatch(TOGGLESELECTEDPOD, pod);

      this.updatePodsUtility();
    },
    submitForm() {
      if (this.selectedServiceId == -1) {
        this.createEmptyTranscript();
      } else {
        // TODO: try to run the job if all criteria is met
      }
    },
    updatePodsUtility() {
      this.errorMessage = '';
      this.errorTranslatable = '';
      if (this.suitablePods.length == 0) {
        const RESETUTILITYPODS = this.$store.getters.keywords.SERVICE.RESETUTILITYPODS;
        this.$store.dispatch(RESETUTILITYPODS);
        return;
      }

      if (!this.selectedService || !this.selectedService.alias) {
        return;
      }

      const payload = {
        serviceAlias: this.selectedService.alias,
        pods: this.suitablePods,
      };

      const UPDATEPODSUTILITY = this.$store.getters.keywords.SERVICE.UPDATEPODSUTILITY;
      this.$store.dispatch(UPDATEPODSUTILITY, payload);
    },
    runJobs: function() {
      this.errorMessage = "";
      this.errorTranslatable = "";
      this.successMessage = "";

      if (!this.selectedService.alias) {
        return;
      }

      let languages = [];
      if (!this.skipTranscribing) {
        if (!this.autoDetect) {
          // languages = this.languageCodes.map((o) => o.value);
          if (this.languageCodes && this.languageCodes.value) {
            languages.push(this.languageCodes.value);
          }

          if (languages.length === 0) {
            this.errorMessage = this.$t("routes.pod.menuCreateTranscript.languageRequired");
            return;
          }
        }
      }

      const payload = {
        serviceAlias: this.selectedService.alias,
        utilityPods: this.runnablePods,
        params: {
          skipTranscribing: this.skipTranscribing ? 1 : 0,
          auto: this.autoDetect,
          languages,
        },
      };

      let self = this;
      this.preventDoubleclick = true;

      setInterval(() => {
        this.preventDoubleclick = false;
      }, 10000);

      const RUNSERVICE = this.$store.getters.keywords.SERVICE.RUNSERVICE;

      this.$store.dispatch(RUNSERVICE, payload)
      .then(function() {
        if (self.utilityPodsErrors.length == 0) {
          self.successMessage = self.$t("routes.pod.menuCreateTranscript.operationQueued");
        }
      })
      .catch(function(error) {
        if (error && error.response && error.response.data && error.response.data.error && error.response.data.error.message) {
          const apiError = error.response.data.error.message;
          if (typeof apiError == 'object') {
            self.errorTranslatable = apiError;
          } else {
            self.errorMessage = apiError;
          }
        } else {
          self.errorMessage = self.$t("backend.system-error");
          console.log(error);   // unhandled error
        }

        self.preventDoubleclick = false;
      });
    },
    refreshServiceOptions: function(serviceAlias) {
      let self = this;
      const REFRESHSERVICEOPTIONS = this.$store.getters.keywords.SERVICE.REFRESHSERVICEOPTIONS;
      this.$store.dispatch(REFRESHSERVICEOPTIONS, serviceAlias)
          .then(function(options) {
            self.serviceOptions = options;
          })
          .then(function() {
            if (serviceAlias === 'aws-transcribe') {
              self.autoDetect = true;
              self.autoDetectDisabled = false;
            } else {
              self.autoDetect = false;
              self.autoDetectDisabled = true;
            }
          });
    },
    createEmptyTranscript() {
      const validForm = this.$refs.form.validate();

      this.errorMessage = "";
      this.successMessage = "";
      this.errorTranslatable = null;

      if (!validForm) {
        return;
      }

      if (!this.pods || this.pods.length === 0) {
        return;
      }

      let self = this;
      const CREATETRANSCRIPT = this.$store.getters.keywords.POD.TRANSCRIPT.CREATETRANSCRIPT;

      let promises = [];

      for (let i = 0; i < this.pods.length; i++) {
        const data = {
          podID: this.pods[i].id,
          payload: {
            name: this.transcriptname,
          }
          // generateFromService: this.generateFromService || null,
        };

        let newPromise = this.$store.dispatch(CREATETRANSCRIPT, data);
        promises.push(newPromise);
      }

      Promise.all(promises)
      .then(function(transcript) {
        self.$emit("refreshSelectedTranscript", transcript);
        self.dialog = false;
      })
      .catch(function(error) {
        if (error && error.response && error.response.data && error.response.data.error) {
          const apiError = error.response.data.error.message;
          if (typeof apiError == 'object') {
            self.errorTranslatable = apiError;
          } else {
            self.errorMessage = apiError;
          }
        } else {
          self.errorMessage = "Error!";
          console.log(error);   // unhandled error
        }
      });


    }
  },
  computed: {
    noThingsSelected: function() {
      // return (
      //   (!this.pods || this.pods.length == 0)
      //   // (!this.nodes || this.nodes.length == 0) &&
      //   // (!this.assets || this.assets.length == 0)
      // );
      return this.suitablePods.length == 0;
    },
    // pod: function() {
    //   const GETVIEWPOD = this.$store.getters.keywords.POD.GETVIEWPOD;
    //   return this.$store.getters[GETVIEWPOD];
    // },
    // pod() {
    //   if (this.pods && this.pods.length > 0) {
    //     return this.pods[0];
    //   }

    //   return null;
    // },
    selectedService() {
      return this.textToSpeechServices.find(x => x.id == this.selectedServiceId);
    },
    creditsLeft: function() {
      if (this.getUtilityPods && this.getUtilityPods[0] && this.getUtilityPods[0].metadata) {
        return this.getUtilityPods[0].metadata.available;
      }

      return 0;
    },
    creditsLeftAfterJob: function() {
      return this.creditsLeft - this.summedCost;
    },
    textToSpeechServices: function() {
      const GETTEXTTOSPEECH = this.$store.getters.keywords.SERVICE.GETTEXTTOSPEECH;
      return this.$store.getters[GETTEXTTOSPEECH];
    },
    suitablePods: function() {
      const GETSELECTEDVIDEOPODS = this.$store.getters.keywords.POD.GETSELECTEDVIDEOPODS;
      let videoPods = this.$store.getters[GETSELECTEDVIDEOPODS];

      const GETSELECTEDAUDIOPODS = this.$store.getters.keywords.POD.GETSELECTEDAUDIOPODS;
      let audioPods = this.$store.getters[GETSELECTEDAUDIOPODS];

      return videoPods.concat(audioPods);
    },
    isSuitableItem: function() {
      return function(item) {
        return Boolean(this.suitablePods.find(x => x.id === item.id));
      };
    },
    getUtilityPods: function() {
      const GETUTILITYPODS = this.$store.getters.keywords.SERVICE.GETUTILITYPODS;
      return this.$store.getters[GETUTILITYPODS];
    },
    runnablePods: function() {
      const GETRUNNABLEPODS = this.$store.getters.keywords.SERVICE.GETRUNNABLEPODS;
      return this.$store.getters[GETRUNNABLEPODS];
    },
    utilityPodsErrors: function() {
      const GETUTILITYPODSERRORS = this.$store.getters.keywords.SERVICE.GETUTILITYPODSERRORS;
      return this.$store.getters[GETUTILITYPODSERRORS];
    },
    isPodRunnable: function() {
      const ISPODRUNNABLE = this.$store.getters.keywords.SERVICE.ISPODRUNNABLE;
      return function(pod) {
        return this.$store.getters[ISPODRUNNABLE](pod);
      };
    },
    getUtilityPod: function() {
      const GETUTILITYPOD = this.$store.getters.keywords.SERVICE.GETUTILITYPOD;
      return function(pod) {
        return this.$store.getters[GETUTILITYPOD](pod);
      };
    },
    summedDuration: function() {
      let sum = 0;
      for (let i = 0; i < this.getUtilityPods.length; i++) {
        if (this.getUtilityPods[i].metadata &&
            this.getUtilityPods[i].metadata.duration !== null &&
            this.getUtilityPods[i].metadata.duration !== undefined) {
          sum += this.getUtilityPods[i].metadata.duration;
        }
      }
      return sum;
    },
    summedCost: function() {
      let sum = 0;
      for (let i = 0; i < this.getUtilityPods.length; i++) {
        if (this.getUtilityPods[i].metadata &&
            this.getUtilityPods[i].metadata.cost !== null &&
            this.getUtilityPods[i].metadata.cost !== undefined) {
          sum += this.getUtilityPods[i].metadata.cost;
        }
      }
      return sum;
    },
    isRunJobsDisabled: function() {
      if (!this.selectedService ||
          this.runnablePods.length === 0 ||
          this.runnablePods.length !== this.pods.length ||
          this.preventDoubleclick === true
      ) {
        return true;
      }

      return false;
    },
    hasMLData: function() {
      let result = false;

      if (this.runnablePods.length > 0) {
        for (const pod of this.runnablePods) {
          if (pod.info.hasData) {
            result = true;
            break;
          }
        }
      }

      return result;
    },

    languages: function() {
      if (this.selectedService && this.serviceOptions && this.serviceOptions.languages) {
        return this.serviceOptions.languages;
      }
      return [];
    },
    showingGenerateAudioBtn: function() {
      // there should be only 1 pod selected
      if (!Array.isArray(this.pods) || this.pods.length !== 1) {
        return false;
      }

      // it should be suitable pod
      if (this.suitablePods.length !== 1) {
        return false;
      }

      const pod = this.pods && this.pods[0] ? this.pods[0] : null;

      const noAudioErrors = ['ERR40', 'ERR12'];
      const utilityPod = this.getUtilityPod(pod);

      if (utilityPod &&
          utilityPod.error &&
          noAudioErrors.indexOf(utilityPod.error.code) >= 0) {
        return true;
      }
      return false;

      // if (pod &&
      //     MimetypeHelper.isMimetypeAudio(pod) &&
      //     pod.asset &&
      //     pod.asset.web &&
      //     pod.asset.web.length > 0) {
      //       return true;
      // } else if (pod &&
      //     MimetypeHelper.isMimetypeVideo(pod) &&
      //     pod.asset &&
      //     pod.asset.audio &&
      //     pod.asset.audio.length > 0
      // ) {
      //   return true;
      // }

      // return false;
    },
    isCreateEmptyTranscriptDisabled() {
      if (
        this.suitablePods &&
        this.suitablePods.length &&
        this.pods && this.pods.length &&
        this.pods.length === this.suitablePods.length)
      {
        return false;
      }

      return true;
    },
  }
}
</script>

<style>
</style>
