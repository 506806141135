<template>
  <v-card outlined class="pa-4">
    <!-- Label input field and add button -->
    <div class="d-flex align-center mb-4">
      <v-text-field
          v-model="newLabel"
          @keyup.enter="addLabel"
          class="mr-4"
          solo flat
          :error-messages="errorMessage"
          hide-details="auto"
      ></v-text-field>

      <div style="align-self: flex-start;">
        <v-btn color="secondaryButtons" @click="addLabel" elevation="0">
          <v-icon >mdi-plus-circle</v-icon>
          {{$t('menus.uploadFiles.addLabel')}}
        </v-btn>
      </div>

    </div>

    <v-container>
      <v-row>
        <!-- Treeview column -->
        <v-col cols="12" md="6">
          <v-treeview
              v-model="selectedLabels"
              v-if="hasLabelCategories"
              :items="categories"
              :label="$t('routes.pod.menuEditLabels.selectCategory')"
              selection-type="leaf"
              selectable return-object open-all
          ></v-treeview>
        </v-col>

        <!-- Chips container column -->
        <v-col cols="12" md="6">
          <!-- Displaying labels as chips -->
          <div class="d-flex flex-wrap">
            <v-chip
                v-for="(item) in labels"
                :key="item.id"
                close
                @click:close="removeLabel(item)"
                class="ma-2"
                label
            >
              {{ item.name }}
            </v-chip>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: "UploadDialogCustomLabels",
  data() {
    return {
      newLabel: '',
      labels: [],
      categories: [],
      selectedLabels: [],
      errorMessage: '',
      flatItems: [],
    };
  },
  watch: {
    selectedLabels: function (newItems, oldItems) {
      for (const selectedItem of newItems) {
        if (selectedItem.isLabel) {
          const exists = this.labels.some(label => label.id === selectedItem.id);
          if (!exists) {
            this.labels.push({ id: selectedItem.id, name: selectedItem.name });
          }
        }
      }

      const itemsToRemove = oldItems
          .filter(oldItem => (
              oldItem.isLabel && !newItems.some(newItem => newItem.id === oldItem.id)
          ))
          .map(item => item.id);

      if (itemsToRemove.length > 0) {
        this.labels = this.labels.filter(label => !itemsToRemove.includes(label.id));
      }
    },
    labels: function () {
      this.$emit('labelsChanged', this.labels.map((label) => label));
    }
  },
  mounted() {
    this.resetState();

    if (this.categories.length === 0) {
      this.loadCategoriesWithLabels();
    }
  },
  methods: {
    addLabel() {
      this.errorMessage = '';
      const newLabelName = this.newLabel.trim();

      if (newLabelName !== '') {
        const labelExists = this.labels.some((label) => label.name === newLabelName);

        if (labelExists) {
          this.errorMessage = this.$t('menus.uploadFiles.labelAlreadyExists');
        } else {
          const existingLabel = this.flatItems.find((item) => item.name === newLabelName);
          if (existingLabel) {
            this.selectedLabels.push(existingLabel);
          } else {
            const newId = `custom-${Date.now()}`;
            this.labels.push({
              id: newId,
              name: newLabelName
            });
          }
        }
      }

      this.newLabel = '';
    },
    removeLabel(item) {
      const hasSelectedLabel = this.selectedLabels.some((selectedLabel) => selectedLabel.id === item.id);

      if (hasSelectedLabel) {
        this.selectedLabels = this.selectedLabels.filter((selectedItem) => (
            !selectedItem.isLabel || selectedItem.id !== item.id
        ));
      }

      this.labels = this.labels.filter((label) => label.id !== item.id);
    },
    loadCategoriesWithLabels: function() {
      const payload = {
        accessPointId: this.accessPointId,
        includeLabels: true,
      };

      const GETCATEGORIES = this.$store.getters.keywords.LABEL.GETCATEGORIES;
      this.$store.dispatch(GETCATEGORIES, payload)
        .then((categories) => {
          this.categories = categories;

          let flatItems = [];

          let stack = this.categories.slice();
          while (stack.length) {
            let node = stack.shift();
            flatItems.push(node);
            stack = stack.concat(node.children || []);
          }

          this.flatItems = flatItems;
        });
    },
    resetState() {
      this.labels = [];
      this.selectedLabels = [];
      this.errorMessage = '';
    }
  },
  computed: {
    accessPointId: function() {
      const ROOTNODEID = this.$store.getters.keywords.AUTH.ROOTNODEID;
      return this.$store.getters[ROOTNODEID];
    },
    hasLabelCategories() {
      return this.categories && this.categories.length > 0;
    },
  },
}
</script>
