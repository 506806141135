<template>
  <div>
    <div v-if="!pod">
      File not found!
    </div>

    <div v-if="pod">
      <div class="d-flex align-center">
        <div class="flex-grow-1">
          <h1>{{$t('routes.pod.menuComment.comments')}}</h1>
        </div>
        <div class="flex-grow-0">
          <v-tooltip bottom open-delay="200" z-index="1200">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
              v-bind="attrs" v-on="on"
              color="primary"
              type="button"
              v-on:click="toggleForm"
              elevation=0
              data-cy="podMenu-comments-addComment"
              >
                <!-- {{$t('routes.pod.menuComment.addComment')}} -->
                <v-icon>mdi-message-plus</v-icon>
              </v-btn>
            </template>

            <span v-if="isVideoPod">{{$t('routes.pod.menuComment.submitCommentToVideo')}}</span>
            <span v-if="isAudioPod">{{$t('routes.pod.menuComment.submitCommentToAudio')}}</span>
          </v-tooltip>

          <v-menu v-model="showingInviteMenu" close-on-content-click offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" elevation=0 color="primary" class="ml-2">
                <v-icon>mdi-account-multiple-plus</v-icon>
                {{$t('routes.pod.menuComment.inviteReviewers')}}
              </v-btn>
            </template>

            <v-list>
              <InviteReviewerDialog>
                <v-list-item @click="stub">
                  <v-list-item-icon>
                    <v-icon small>mdi-tooltip-account</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{$t('routes.pod.menuComment.inviteInternalReviewers')}}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </InviteReviewerDialog>

              <AccesslinkCreateDialog
                v-if="pod && pod.node"
                :selectedPods="[pod]"
                :selectedNodes="[]"
                :rootNode="pod.node"
                :setAllowComments="true"
                :setNotifyViaEmail="true"
              >
                <v-list-item @click="stub">
                  <v-list-item-icon>
                    <v-icon small>mdi-share-circle</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('routes.pod.menuComment.inviteExternalReviewers') }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </AccesslinkCreateDialog>

            </v-list>
          </v-menu>



            <!-- <v-btn elevation=0 color="primary" class="ml-2">
              <v-icon>mdi-account-multiple-plus</v-icon>
              {{$t('routes.pod.menuComment.inviteReviewers')}}
            </v-btn> -->
        </div>
      </div>

      <PodCommentForm
        v-if="showAddCommentForm"
        v-on:visibility="showAddCommentForm = $event"
        class="mt-4"
        data-cy="podMenu-comments-commentForm">
      </PodCommentForm>

      <PodCommentItem
        v-for="comment in comments"
        v-bind:key="comment.id"
        v-bind:comment="comment"
        v-bind:level="0"
        data-cy="podMenu-comments-commentItem">
      </PodCommentItem>

      <div
        v-if="$isFCPExtension() && comments.length > 0"
        class="text-center my-4">
        <v-btn color="primary" type="button" v-on:click="addCommentsToFCP" small>
          {{$t('routes.node.FCPAdd')}}
        </v-btn>
      </div>

    </div>
  </div>
</template>

<script>
import PodCommentForm from "./PodCommentForm.vue"
import PodCommentItem from "./PodCommentItem.vue"
import InviteReviewerDialog from "./InviteReviewerDialog.vue"
import AccesslinkCreateDialog from '@/routes/nodes2/components/dialogs/AccesslinkCreateDialog.vue'
import Helper from '@/components/helper';

export default {
  name: "CommentsToolbar",
  components: {
    PodCommentForm,
    PodCommentItem,
    InviteReviewerDialog,
    AccesslinkCreateDialog,
  },
  data: function() {
    return {
      showingInviteMenu: false,
      showAddCommentForm: false,
      reviewNote: '',
    }
  },
  mounted: function() {
    // if (this.$canListPodService()) {
    //   const REFRESHCOMMENTS = this.$store.getters.keywords.POD.COMMENT.REFRESHCOMMENTS;
    //   this.$store.dispatch(REFRESHCOMMENTS, this.pod.id);
    // }
  },
  methods: {
    stub: function() {
      // do nothing
      this.showingInviteMenu = false;
    },
    toggleForm: function() {
      this.showAddCommentForm = !this.showAddCommentForm;
      if (this.showAddCommentForm) {
        this.closeOtherForms();
      }
    },
    closeOtherForms: function() {
      const value = 'add';
      const SETCLOSEFORMSEVENT = this.$store.getters.keywords.POD.COMMENT.SETCLOSEFORMSEVENT;
      this.$store.dispatch(SETCLOSEFORMSEVENT, value);
    },
    addCommentsToFCP: function() {
      const mappedComments = this.comments.map((c) => this.mapVBCommentToFCPComment(c));
      const FCPObject = {
        action: "addComments",
        comments: mappedComments,
        fileName: this.pod.name,
        fileDuration: this.pod.asset.web[0].duration,
        frameRate: this.pod.asset.web[0].framerate
      };
      const finalObject = JSON.stringify(FCPObject);
      Helper.invokeFCPMessageHandler('comments', finalObject);
    },
    mapVBCommentToFCPComment: function (comment) {
      var name = "";
      if (comment.createdby && comment.createdby.firstname && comment.createdby.lastname) {
        name = comment.createdby.firstname + " " + comment.createdby.lastname;
      } else if (comment.email) {
        name = comment.email;
      }
      const note = comment.content;
      const date = comment.createdat;
      const start = comment.start;
      const end = comment.end;

      const object = {
        name,
        note,
        date,
        start,
        end,
      };
      return object;

    }

  },
  computed: {
    pod: function() {
      const GETVIEWPOD = this.$store.getters.keywords.POD.GETVIEWPOD;
      return this.$store.getters[GETVIEWPOD];
    },
    comments: function() {
      const GETCOMMENTS = this.$store.getters.keywords.POD.COMMENT.GETCOMMENTS;
      return this.$store.getters[GETCOMMENTS](null);
    },
    isVideoPod: function () {
      const ISVIDEOPOD = this.$store.getters.keywords.POD.ISVIDEOPOD;
      return this.$store.getters[ISVIDEOPOD];
    },
    isAudioPod: function () {
      const ISAUDIOPOD = this.$store.getters.keywords.POD.ISAUDIOPOD;
      return this.$store.getters[ISAUDIOPOD];
    },
    totalVisibleForms: function() {
      const GETVISIBLEFORMS = this.$store.getters.keywords.POD.COMMENT.GETVISIBLEFORMS;
      return this.$store.getters[GETVISIBLEFORMS];
    },
    showingAddComment: function() {
      return this.$canControlPodService() && !this.showAddCommentForm && this.totalVisibleForms == 0;
    },
  }
}
</script>

<style>
</style>
