import API from '../AxiosInstance';

export default {
  getAccesslinksByPod: function(podID) {
    const body = {};

    return API.get('pods/' + podID + '/accesslinks', body);
  },

  getAccesslinksByNode: function(nodeId) {
    const body = {};
    return API.get(`nodes/${nodeId}/accesslinks`, body);
  },

  createAccesslink: function(data) {
    const body = data;
    return API.post('accesslinks', body);
  },

  updateAccesslink: function(id, data) {
    const body = data;
    return API.patch('accesslinks/' + id, body);
  },

  deleteAccesslink: function(accessLinkId) {
    const body = { };
    return API.delete('accesslinks/' + accessLinkId, body);
  },

  inviteAccesslink: function(payload) {
    const body = {
      receiver: payload.receiver,
      note: payload.note,
    };

    return API.post('accesslinks/' + payload.linkId + '/invite', body);
  },

  // external api call
  getAccesslinkInfo: function(code) {
    const body = {};
    return API.get('reviewaccess/' + code, body);
  },

  // external api call
  authorizeAccesslink: function(data) {
    const body = data;
    return API.post('reviewaccess/authorize', body);
  },

  getAccesslinkContents: function(data) {
    const options = {
      headers: {
        'vb-access-token': data.accesstoken,
      }
    };

    return API.get('reviewaccess/files/info', options);
  },

  addComment: function(data) {
    const body = data.formData;
    const options = {
      headers: {
        'vb-access-token': data.accesstoken,
      },
    };

    return API.post('reviewaccess/comments', body, options);
  },

  updateComment: function(data) {
    const body = data.formData;
    const options = {
      headers: {
        'vb-access-token': data.accesstoken,
      },
    };

    return API.patch('reviewaccess/comments/' + data.id, body, options);
  },

  deleteComment: function(data) {
    const options = {
      headers: {
        'vb-access-token': data.accesstoken,
      },
    };

    return API.delete(`reviewaccess/comments/${data.id}`, options);
  },

  downloadAccesslinkFiles: function(data) {

    const queryStringParameters = [
      `vb-access-token=${data.accesstoken}`,
      // add more parameters here if needed
    ]
    .join('&');


    const endpoint = API.defaults.baseURL;
    const url = `${endpoint}reviewaccess/${data.code}/download?${queryStringParameters}`;

    // console.log("endpoint is", endpoint);
    // console.log("url is", url);
    window.location = url;
  },
}
